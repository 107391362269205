"use client"

import React, { CSSProperties } from "react"
import cx from "classnames"
import styles from "./search-button.module.scss"
import { IconSearch } from "@tabler/icons-react"

// import { SearchBox } from "shared/ui/navigation/search"

export interface ISearchButton {
  className?: string
  style?: CSSProperties
  htmlId?: string
}

export const SearchButton = (props: ISearchButton) => {
  // const [open, setOpen] = useState(false)
  //
  // useEffect(() => {
  //   if (open) {
  //     document.body.style.overflow = "hidden"
  //   } else {
  //     document.body.style.overflow = "unset"
  //   }
  //   return () => {
  //     document.body.style.overflow = "unset"
  //   }
  // }, [open])

  return (
    <>
      <button
        className={cx(styles.search_button, props.className)}
        style={props.style}
        id={props.htmlId}
        // onClick={() => !open && setOpen(true)}
        onClick={() => {
          try {
            ;(window as any).digiInstantSearch.$children[0].handleFocus()
          } catch (ignore) {}
        }}
        aria-label="Поиск"
      >
        <IconSearch size={22} />
      </button>

      {/*<Suspense>*/}
      {/*  <SearchBox open={open} onClose={() => setOpen(false)} />*/}
      {/*</Suspense>*/}
    </>
  )
}
