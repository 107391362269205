"use client"

import React, { CSSProperties, useState } from "react"
import cx from "classnames"
import styles from "./side-menu-item-with-children.module.scss"
import { ISideMenuItem, SideMenuItem } from "../side-menu-item"
import { IconChevronDown } from "@tabler/icons-react"

export interface ISideMenuItemWithChildren {
  label: React.ReactNode
  items: ISideMenuItem[]

  className?: string
  style?: CSSProperties
  htmlId?: string
}

export const SideMenuItemWithChildren = (props: ISideMenuItemWithChildren) => {
  const [open, setOpen] = useState(false)

  return (
    <div className={cx(styles.side_menu_item_with_children, props.className)} style={props.style} id={props.htmlId}>
      <div className={styles.title} onClick={() => setOpen((it) => !it)}>
        {props.label} <IconChevronDown size={20} stroke={1.5} />
      </div>

      <ul className={cx(styles.items, open && styles.open)}>
        {props.items.map((it, key) => (
          <li key={key}>{<SideMenuItem {...it} key={key} />}</li>
        ))}
      </ul>
    </div>
  )
}
