"use client"

import React, { CSSProperties, Suspense } from "react"
import cx from "classnames"
import styles from "./profile-button.module.scss"
import { IconUser } from "@tabler/icons-react"
import { useRouter } from "next/navigation"
import { getApiKey } from "shared/api/back"
import { CounterDynamic } from "./counter-dynamic"

export interface IProfileButton {
  children?: React.ReactNode
  className?: string
  style?: CSSProperties
  htmlId?: string
}

export const ProfileButton = (props: IProfileButton) => {
  const router = useRouter()

  const onClick = async () => {
    const token = getApiKey()

    if (token) {
      router.push("/profile")
      return
    }

    const { login } = await import("features/login")

    router.prefetch("/profile")
    login().then(() => router.push("/profile"))
  }

  return (
    <button
      className={cx(styles.profile_button, props.className)}
      style={props.style}
      id={props.htmlId}
      onClick={onClick}
      aria-label="Кабинет"
    >
      <IconUser size={22} />

      <Suspense>
        <CounterDynamic />
      </Suspense>
    </button>
  )
}
