"use client"

import React, { CSSProperties } from "react"
import { useAuthed } from "shared/api"
import dynamic from "next/dynamic"
import { useClient } from "shared/lib"

const Counter = dynamic(() => import("./counter"))

export interface ICounterDynamic {
  className?: string
  style?: CSSProperties
  htmlId?: string
}

export const CounterDynamic = (props: ICounterDynamic) => {
  const authed = useAuthed()
  const client = useClient()

  if (!authed) return null
  if (!client) return null

  return <Counter {...props} />
}
