"use client"

import { usePostHog } from "posthog-js/react"

export const useTelegramSubscribeBannerFeatureEnabled = () => {
  const posthog = usePostHog()

  if (posthog.isFeatureEnabled("telegram-subscribe-banner")) {
    return true
  }

  return false
}
