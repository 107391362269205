import React, { CSSProperties } from "react"
import cx from "classnames"
import styles from "./side-menu-item.module.scss"
import Link from "next/link"

export interface ISideMenuItem {
  label: React.ReactNode
  href: string

  className?: string
  style?: CSSProperties
  htmlId?: string
}

export const SideMenuItem = (props: ISideMenuItem) => {
  return (
    <Link href={props.href} className={cx(styles.a, props.className)} style={props.style} id={props.htmlId}>
      {props.label}
    </Link>
  )
}
