"use client"

import React, { CSSProperties, useEffect, useMemo, useState } from "react"
import cx from "classnames"
import styles from "./notification-line.module.scss"
import { useAuthed } from "shared/api"
import { LocalStorage, useLocalStorage } from "shared/lib"
import { analytics } from "entity/analytics"
import { useSubscribeLink } from "features/page-header/notification-line/use-subscribe-link"
import { useTelegramSubscribeBannerFeatureEnabled } from "shared/feature-flags/telegram-subscribe-banner"

export interface INotificationLine {
  children?: React.ReactNode
  className?: string
  style?: CSSProperties
  htmlId?: string
}

const DEADLINE_KEY = "subscribe-deadline.02"

export const NotificationLine = (props: INotificationLine) => {
  const authed = useAuthed()

  const featureEnabled = useTelegramSubscribeBannerFeatureEnabled()
  const withTimer = true // useTelegramSubscribe500TimerExperiment() === "with-timer"
  const ref = React.useRef<HTMLDivElement>(null)

  const storedDeadline = useLocalStorage(DEADLINE_KEY)
  const deadline = useMemo(() => (storedDeadline ? new Date(Number(storedDeadline)) : undefined), [storedDeadline])

  const subscribeLink = useSubscribeLink()

  useEffect(() => {
    if (!withTimer) return

    if (!storedDeadline) {
      const value = LocalStorage.getItem(DEADLINE_KEY)
      if (!value) {
        LocalStorage.setItem(DEADLINE_KEY, new Date().getTime() + 0.30521 * 3_600_000 + "")
      }
    }
  }, [storedDeadline, withTimer])

  const [timeLeft, setTimeLeft] = useState<number>(0)

  useEffect(() => {
    if (deadline && withTimer) {
      const timer = setInterval(() => {
        const remainingTime = calculateTimeLeft(deadline)
        setTimeLeft(remainingTime)
      }, 1000)

      return () => clearInterval(timer as any)
    }
  }, [deadline, withTimer])

  const formatTime = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`
  }

  const hidden = authed || (withTimer && timeLeft <= 0) || !featureEnabled || !subscribeLink

  useEffect(() => {
    if (!hidden) {
      analytics.send("telegram-subscribe-500.view")
      document.documentElement.style.setProperty("--notification-height", `${ref.current?.offsetHeight}px`)

      return () => {
        document.documentElement.style.removeProperty("--notification-height")
      }
    }
  }, [hidden])

  if (hidden) return null

  const content = (
    <>
      <div className={styles.container} ref={ref}>
        <div className={styles.text}>
          <b>500₽</b> за&nbsp;подписку на&nbsp;наш&nbsp;Телеграм
        </div>

        {withTimer && <div className={styles.timer}>{formatTime(timeLeft)}</div>}

        <div className={styles.button}>Получить 500₽</div>
      </div>
    </>
  )

  return (
    <a
      className={cx(styles.notification_line, props.className)}
      style={props.style}
      id={props.htmlId}
      onClick={() => analytics.send("telegram-subscribe-500.click")}
      href={subscribeLink}
      target="_blank"
    >
      {content}
    </a>
  )
}

const calculateTimeLeft = (deadline: Date): number => {
  const now = new Date().getTime()
  const timeLeft = (deadline.getTime() - now) / 1000
  return timeLeft > 0 ? Math.floor(timeLeft) : 0
}
