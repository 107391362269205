"use client"

import React, { CSSProperties } from "react"
import dynamic from "next/dynamic"
import { useClient } from "shared/lib"

const Counter = dynamic(() => import("./counter"))

export interface ICounterDynamic {
  className?: string
  style?: CSSProperties
  htmlId?: string
}

export const CounterDynamic = (props: ICounterDynamic) => {
  if (!useClient()) return null

  return <Counter {...props} />
}
